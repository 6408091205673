.App {
  padding: 20px;
  font-size: 14px;
  font-family: sans-serif;
  background-color: #e5eaec;
  min-height: 100vh;
}

p, h3, h1, h2, h5 {
  margin: 0;
}

ul, li {
}

button {
  padding: 6px 20px;
  border: 0;
  background-color: blue;
  color: #fff;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App > h2 {
  margin-bottom: 10px
}

.indicatorForm {
  margin-bottom: 20px;
  background: #d7dee8;
    border-radius: 10px;
    padding: 6px 10px;
}

.indicatorHeader {
  display: flex;
    align-items: baseline;
}

.parserSelect {
  width: 200px;
}


.indicatorTitle {
  min-width: 260px;
}

.indicatorSelect {
}

.paramsList {
  width: 100%;
    max-width: 400px;
    margin: 10px;
    padding: 10px;
    background: #d0d3e4;
    border-radius: 10px;
}

.paramsListHeader {
  display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    /* font-size: 16px; */
    font-weight: bold;
    border-bottom: 1px solid #a2a2a2;
    padding-bottom: 6px;
}

.paramsListRow {
  display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.paramsSelect {}

.title {
  margin-bottom: 20px;
}

.parserInputSection {
  margin-bottom: 20px;
}

.chartType {
  display: flex;
    width: 100%;
    max-width: 300px;
    margin-bottom: 16px;
    justify-content: space-between;
}

.parserInput {}

.parserBtn {
  display: block;
    margin-top: 20px;
    padding: 6px 20px;
}

.content {}

.saveBtn {
  /* margin-top: 20px; */
}

.toggleOptionMapBtn {
  padding: 2px;
    height: 20px;
    background: transparent;
    color: blue;
}

.optionsMapRow {
  display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}